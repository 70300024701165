// For Audi there are no icons specified yet, change the values once they are
export enum DeviceTypeIcon {
  ASTERISK = "asterisk",
  CAR = "car",
  BARRIER = "barrier",
  BIKE = "bike",
  DOOR = "door",
  LOCKER = "locker",
}

export enum DeviceTypeEnum {
  BARRIER = "BARRIER",
  BIKE_BOX = "BIKE_BOX",
  PARKING_LOT_WIRELESS = "PARKING_LOT_WIRELESS",
  PARKING_LOT = "PARKING_LOT",
  STORAGE_BOX = "STORAGE_BOX",
  VIRTUAL = "VIRTUAL",
}

export class DeviceType {
  originType: string;

  icon: string;

  translationKey: string;

  constructor(type: string) {
    this.originType = type;
    this.icon = DeviceType.getIconFor(type);
    this.translationKey = DeviceType.getTranslationKeyFor(type);
  }

  static getIconFor(type: string): string {
    switch (type) {
      case DeviceTypeEnum.BARRIER:
        return DeviceTypeIcon.BARRIER;
      case DeviceTypeEnum.BIKE_BOX:
        return DeviceTypeIcon.BIKE;
      case DeviceTypeEnum.VIRTUAL:
      case DeviceTypeEnum.PARKING_LOT:
      case DeviceTypeEnum.PARKING_LOT_WIRELESS:
        return DeviceTypeIcon.CAR;
      case DeviceTypeEnum.STORAGE_BOX:
        return DeviceTypeIcon.LOCKER;
      default:
        return DeviceTypeIcon.ASTERISK;
    }
  }

  static getTranslationKeyFor(type: string): string {
    switch (type) {
      case DeviceTypeEnum.BARRIER:
        return "Barrier";
      case DeviceTypeEnum.BIKE_BOX:
        return "Bike Box";
      case DeviceTypeEnum.VIRTUAL:
      case DeviceTypeEnum.PARKING_LOT:
      case DeviceTypeEnum.PARKING_LOT_WIRELESS:
        return "Parking Lot";
      case DeviceTypeEnum.STORAGE_BOX:
        return "Storage Box";
      default:
        return `${type.charAt(0).toUpperCase()}${type.slice(1).toLowerCase()}`;
    }
  }
}
