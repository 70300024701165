<template>
  <v-list
    dense
    nav
    flat
    :dark="$vuetify.breakpoint.mobile"
    :color="$vuetify.breakpoint.mobile ? 'navbar' : null"
  >
    <v-row>
      <v-col cols="auto">
        <v-img src="@/assets/Audi_Rings_wh-RGB.svg" contain width="91px" class="mb-3 ml-1 mt-1" />
      </v-col>
      <v-spacer />
      <v-col v-if="$vuetify.breakpoint.mobile" cols="auto">
        <v-btn icon :ripple="false" @click="$emit('close-navbar')">
          <audi-icon icon="cancel" dark size="32px" />
        </v-btn>
      </v-col>
    </v-row>
    <template v-for="(section, index) in sections">
      <v-subheader
        :key="`section-subheader-${section}`"
        :class="{
          'caption-size caption-color mb-n2': true,
          'mt-3': section !== 'VIS',
        }"
      >
        {{ sectionTitles[section] }}
      </v-subheader>
      <template v-if="section !== 'CUSTOMER_API'">
        <template v-for="(route, routeIndex) in $router.options.routes">
          <v-list-item
            v-if="shouldDisplayNavEntry(section, route)"
            :key="`route-${index}${routeIndex}`"
            class="mb-0"
            exact
            :to="route.path"
            active-class="white--text"
            :ripple="false"
            @click="$emit('close-navbar')"
          >
            <v-list-item-content>
              <v-list-item-title
                :class="{
                  'white--text font-weight-bold': isUrlActive(route.path),
                  'list-item-text': !isUrlActive(route.path),
                }"
              >
                {{ $t(route.meta.navbar.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="shouldDisplayNavEntry(section, route) && !route.meta.navbar.lastInSection"
            :key="`div-${index}${routeIndex}`"
            class="ml-2"
          ></v-divider>
        </template>
      </template>

      <template
        v-if="section === 'CUSTOMER_API' && $store.getters.userHasFeatures(['CUSTOMER_API'])"
      >
        <v-list-group
          :key="`group-${index}`"
          active-class="white--text"
          :ripple="false"
          class="mx-n5"
          @click="setActive('customer-api')"
        >
          <template #activator>
            <v-list-item-title
              :class="{
                'ml-5': true,
                'font-weight-bold white--text': isActive('customer-api'),
                'list-item-text': !isActive('customer-api'),
              }"
            >
              {{ $t("Customer API") }}
            </v-list-item-title>
          </template>
          <template #appendIcon>
            <audi-icon class="mr-2" icon="forward" dark :style="rotationStyle('customer-api')" />
          </template>
          <div class="subitems-background">
            <template v-for="(route, routeIndex) in $router.options.routes">
              <v-list-item
                v-if="shouldDisplayNavEntry('CUSTOMER_API', route)"
                :key="`route-${index}${routeIndex}`"
                :to="route.path"
                active-class="white--text font-weight-bold"
                :ripple="false"
                @click="
                  $emit('close-navbar');
                  setActive(`customer-api.${index}${routeIndex}`);
                "
              >
                <v-list-item-content>
                  <v-list-item-title
                    :class="{
                      'ml-12': true,
                      'white--text font-weight-bold': isActive(
                        `customer-api.${index}${routeIndex}`,
                      ),
                      'list-item-text': !isActive(`customer-api.${index}${routeIndex}`),
                    }"
                  >
                    {{ $t(route.meta.navbar.title) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="
                  shouldDisplayNavEntry('CUSTOMER_API', route) && !route.meta.navbar.lastInSection
                "
                :key="`divider-${index}${routeIndex}`"
                class="ml-14 mr-5"
              />
            </template>
          </div>
        </v-list-group>
      </template>
    </template>
    <v-list-group
      active-class="white--text"
      :ripple="false"
      class="mx-n5"
      @click="setActive('help')"
    >
      <template #activator>
        <v-list-item-title
          :class="{
            'ml-5': true,
            'white--text font-weight-bold': isActive('help'),
            'list-item-text': !isActive('help'),
          }"
        >
          {{ $t("Help") }}
        </v-list-item-title>
      </template>

      <template #appendIcon>
        <audi-icon class="mr-2" icon="forward" dark :style="rotationStyle('help')" />
      </template>

      <div class="subitems-background">
        <v-list-item two-line class="ml-12">
          <v-list-item-content>
            <v-list-item-title class="caption-size caption-color">
              {{ $t("Contact Support") }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption-size">
              <span class="caption-color">
                {{ $t("Email") + ": " }}
              </span>
              <a
                style="color: rgba(255, 255, 255, 0.7)"
                href="mailto://audi.support@book-n-park.de"
              >
                audi.support@book-n-park.de
              </a>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list-group>

    <v-divider v-if="!isActive('help')" class="ml-2" />

    <v-list-group
      active-class="white--text"
      :ripple="false"
      class="mx-n5"
      @click="setActive('language')"
    >
      <template #activator>
        <v-list-item-title
          :class="{
            'ml-5': true,
            'white--text font-weight-bold': isActive('language'),
            'list-item-text': !isActive('language'),
          }"
        >
          {{ $t("Language") }}
        </v-list-item-title>
      </template>

      <template #appendIcon>
        <audi-icon class="mr-2" icon="forward" dark :style="rotationStyle('language')" />
      </template>

      <div class="subitems-background">
        <div class="ml-12">
          <v-list-item
            active-class="nav-active"
            :ripple="false"
            @click="$store.dispatch('translateTo', languages.DE)"
          >
            <v-list-item-title
              :class="{
                'white--text font-weight-bold': isLanguageActive(languages.DE),
                'list-item-text': !isLanguageActive(languages.DE),
              }"
            >
              Deutsch
            </v-list-item-title>
          </v-list-item>

          <v-divider class="ml-2 mr-5" />

          <v-list-item
            active-class="nav-active"
            :ripple="false"
            @click="$store.dispatch('translateTo', languages.EN)"
          >
            <v-list-item-title
              :class="{
                'white--text font-weight-bold': isLanguageActive(languages.EN),
                'list-item-text': !isLanguageActive(languages.EN),
              }"
            >
              English
            </v-list-item-title>
          </v-list-item>

          <v-divider class="ml-2 mr-5" />

          <v-list-item
            active-class="nav-active"
            :ripple="false"
            @click="$store.dispatch('translateTo', languages.FR)"
          >
            <v-list-item-title
              :class="{
                'white--text font-weight-bold': isLanguageActive(languages.FR),
                'list-item-text': !isLanguageActive(languages.FR),
              }"
            >
              Français
            </v-list-item-title>
          </v-list-item>

          <v-divider class="ml-2 mr-5" />

          <v-list-item
            active-class="nav-active"
            :ripple="false"
            @click="$store.dispatch('translateTo', languages.IT)"
          >
            <v-list-item-title
              :class="{
                'white--text font-weight-bold': isLanguageActive(languages.IT),
                'list-item-text': !isLanguageActive(languages.IT),
              }"
            >
              Italiano
            </v-list-item-title>
          </v-list-item>
        </div>
      </div>
    </v-list-group>

    <v-divider v-if="!isActive('language')" class="ml-2" />

    <v-list-group
      active-class="white--text"
      :ripple="false"
      class="mx-n5"
      @click="setActive('user')"
    >
      <template #activator>
        <v-list-item-title
          :class="{
            'ml-5': true,
            'white--text font-weight-bold': isActive('user'),
            'list-item-text': !isActive('user'),
          }"
        >
          {{ $t("Profile") }}
        </v-list-item-title>
      </template>

      <template #appendIcon>
        <audi-icon class="mr-2" icon="forward" dark :style="rotationStyle('user')" />
      </template>

      <div class="subitems-background">
        <div class="ml-12">
          <v-list two-line color="transparent">
            <v-list-item class="dense-two-line mb-n3">
              <v-list-item-content>
                <v-list-item-title class="caption-size caption-color">
                  {{ $t("Username") }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption-size">
                  {{ $store.getters.user.username }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$store.getters.user.tenant" class="dense-two-line mb-n3">
              <v-list-item-content>
                <v-list-item-title class="caption-size caption-color">
                  {{ $t("Tenant") }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption-size">
                  {{ $store.getters.user.tenant.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="dense-two-line mb-n3">
              <v-list-item-content>
                <v-list-item-title class="caption-size caption-color">
                  {{ $t("Role") }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="$store.getters.userIsSuperReservationManager"
                  class="caption-size"
                >
                  Super Reservation Manager
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else class="caption-size">
                  {{ $store.getters.user.role.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="mb-n3">
              <v-list-item-content>
                <v-list-item-title class="caption-size caption-color">
                  {{ $t("Credit Balance") }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption-size">
                  {{ $store.getters.getAccountBalance.toFixed(2) }}€
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$store.getters.user.zones.length > 0" class="height-auto mb-n3">
              <v-list-item-content>
                <v-list-item-title class="caption-size caption-color">
                  {{ $t("Zones") }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption-size">
                  <pre> {{ userZones }} </pre>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="caption-size caption-color">
                  {{ $t("Version") }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption-size">
                  {{ getVersion() }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list-item
            :to="urls.PROFILE"
            active-class="white--text"
            :ripple="false"
            @click="$emit('close-navbar')"
          >
            <v-list-item-title
              :class="{
                'white--text font-weight-bold': isUrlActive(urls.PROFILE),
                'list-item-text': !isUrlActive(urls.PROFILE),
              }"
            >
              {{ $t("Settings") }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </div>
    </v-list-group>

    <v-divider v-if="!isActive('user')" class="ml-2" />

    <v-list-item
      class="mb-12"
      active-class="white--text"
      :ripple="false"
      @click="
        logout();
        setActive('logout');
      "
    >
      <v-list-item-title
        :class="{
          'white--text font-weight-bold': isActive('logout'),
          'list-item-text': !isActive('logout'),
        }"
      >
        {{ $t("Logout") }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<script>
import { Languages } from "@/model/languages";
import urls from "@/router/urls";
import AudiIcon from "@/components/audi/AudiIcon.vue";

export default {
  components: { AudiIcon },
  data() {
    return {
      urls,
      languages: Languages,
      activeName: "",
      lastActiveName: "",
    };
  },
  computed: {
    sections() {
      if (this.$store.getters.user.isReservationManager()) {
        return ["VIS", "RM", "CUSTOMER_API", "FUNDAMENTALS"];
      }

      if (this.$store.getters.user.isAdmin()) {
        return [
          "OVERVIEW",
          "DATABASE",
          "CUSTOMERS",
          "ACL",
          "INTERNALS",
          "SUPPORT",
          "DEPLOYMENT",
          "FUNDAMENTALS",
        ];
      }

      if (this.$store.getters.user.isSupport()) {
        return ["SUPPORT", "FUNDAMENTALS"];
      }

      return ["VIS", "FUNDAMENTALS"];
    },
    sectionTitles() {
      return {
        VIS: this.$t("Your Reservations"),
        RM: this.$t("Administration"),
        CUSTOMER_API: "API",
        FUNDAMENTALS: this.$t("Fundamentals"),
        OVERVIEW: this.$t("Overview"),
        CUSTOMERS: this.$t("Customers"),
        ACL: "ACL",
        INTERNALS: this.$t("Internals"),
        SUPPORT: "Support",
        DEPLOYMENT: "Deployment",
        DATABASE: this.$t("Database"),
      };
    },
    userZones() {
      const zones = this.$store.getters.user.zones.reduce(
        (string, currZone) => `${string + currZone.name},<br>`,
        "",
      );
      return zones.slice(0, -5);
    },
  },
  methods: {
    /**
     * Sets the given ID as active id. If the given ID is already active, deactivates it.
     */
    setActive(name) {
      if (this.activeName.includes(name)) {
        this.lastActiveName = this.activeName;
        this.activeName = "";
        return;
      }

      // If the last active name contained the new one, this means we opened the drawer that still
      // has an active element. So make the last active element the current active one.
      if (this.lastActiveName.includes(name)) {
        this.activeName = this.lastActiveName;
        return;
      }

      this.lastActiveName = this.activeName;
      this.activeName = name;
    },
    isActive(name) {
      return this.activeName.includes(name);
    },
    isUrlActive(url) {
      return this.$route.path.includes(url);
    },
    isLanguageActive(lang) {
      return this.$store.getters.language === lang;
    },
    rotationStyle(name) {
      return this.isActive(name)
        ? "transition: all 0.1s ease 0s; transform: rotate(90deg)"
        : "transition: all 0.1s ease 0s";
    },
    shouldDisplayNavEntry(section, route) {
      return (
        route.meta.navbar &&
        this.$store.getters.userHasFeatures(route.meta.features) &&
        route.meta.navbar.sections.includes(section)
      );
    },
    async logout() {
      await this.$router.push(urls.LOGIN);
      this.$store.dispatch("logout");
      this.$audiOidcUserManager.signoutSilent();
    },
    getVersion() {
      return process.env.VUE_APP_VERSION;
    },
  },
};
</script>

<style scoped>
.invisible-link {
  text-decoration: none;
}
.list-item-text {
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.2s ease 0s;
}
.list-item-text:hover {
  color: rgba(255, 255, 255, 1) !important;
}
.subitems-background {
  background-color: #2b2b2b;
}
.caption-size {
  font-size: 12px !important;
}
.caption-color {
  color: #999999 !important;
}
</style>
