import { addMinutes } from "date-fns";

class ReservationDates {
  public start: Date;

  public end: Date;

  /**
   * Creates new ReservationDates based on the start of the reservation. The second parameter
   * can either be a Date object or a number, which determines the offset from the start in minutes.
   * @param start The start of the reservation
   * @param endDateOrDifferenceInMins If a Date is given, sets the end date of the reservation.
   * If a number is given, this determines the offset from the start date in minutes
   */
  constructor(start: Date, endDateOrDifferenceInMins: Date | number) {
    this.start = start;

    if (typeof endDateOrDifferenceInMins === "number") {
      this.end = addMinutes(start, endDateOrDifferenceInMins);
      return;
    }

    this.end = endDateOrDifferenceInMins;
  }

  /**
   * Checks that both dates are set, and the start date is earlier than the end date.
   * @returns True if the dates are sufficient
   */
  sufficient(): boolean {
    return this.start !== undefined && this.end !== undefined && this.start < this.end;
  }
}

export default ReservationDates;
