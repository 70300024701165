<template>
  <v-img
    class="audiicon"
    contain
    v-bind="$attrs"
    :src="`/audi-static/${icon}-${large ? 'large' : 'small'}.svg`"
    :style="dark ? 'filter: invert(100%)' : ''"
    :max-width="size"
  />
</template>

<script>
export default {
  name: "AudiIcon",
  props: {
    icon: {
      required: true,
      type: String,
    },
    large: {
      required: false,
      type: Boolean,
      default: false,
    },
    dark: {
      required: false,
      type: Boolean,
      default: false,
    },
    size: {
      required: false,
      type: String,
      default: "24px",
    },
  },
};
</script>

<style scoped></style>
