import Vue from "vue";
import AxiosRestService from "@/network/axiosRestService";
import { State } from "@/model/device";

const initialState = () => ({
  zones: [],
  zoneID: 0,
  loading: false,
  editing: false,
  errorGetParkingLots: false,
  errorEditingItem: false,
  successEditingItem: false,
  rebootResponseCode: 200,
  refreshResponseCode: 200,
  recalibrateResponseCode: 200,
});

/**
 * Helper function to send a command. Not implemented as action because actions
 * can't return values to the caller
 * @param deviceId The id of the device for which to perform the command
 * @param timeout The device status timeout in seconds
 * @param desiredState The desired state of the device after the interaction
 * @return Promise<Response> The promise returned by the axios call
 */
function sendCommand(deviceId, timeout, desiredState) {
  const payload = {
    meta: {
      clientId: "book-n-park::frontend::store::parkinglotStatus",
    },
    data: {
      type: "devices",
      id: `${deviceId}`,
      attributes: {
        status: desiredState,
      },
    },
  };

  // Check if timeout is set, then set the payload attribute
  if (timeout) {
    payload.meta.checkDeviceStatusTimeoutInSeconds = timeout;
  }

  return AxiosRestService.patch(`/devices/${deviceId}`, payload, {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  });
}

export default {
  namespaced: true,

  state: initialState(),

  getters: {
    issues: (state) => state.issues,
    zones: (state) => state.zones,
    zoneID: (state) => state.zoneID,
    loading: (state) => state.loading,
    editing: (state) => state.editing,
    setErrorGetParkingLots: (state) => state.errorGetParkingLots,
    errorEditingItem: (state) => state.errorEditingItem,
    successEditingItem: (state) => state.successEditingItem,
    isEmpty: (state) => !(Array.isArray(state.zones) && state.zones.length),
    rebootResponseCode: (state) => state.rebootResponseCode,
    refreshResponseCode: (state) => state.refreshResponseCode,
    recalibrateResponseCode: (state) => state.recalibrateResponseCode,
  },

  mutations: {
    setIssues: (state, issues) => Vue.set(state, "issues", issues),
    setZones: (state, zones) => {
      zones
        .sort((zoneA, zoneB) => {
          // Dont compare if null
          if (zoneA.name && zoneB.name) {
            return zoneA.name.localeCompare(zoneB.name);
          }
          return 0;
        })
        .forEach((zone) => {
          zone.sites
            .sort((siteA, siteB) => {
              // Dont compare if null
              if (siteA.name && siteB.name) {
                return siteA.name.localeCompare(siteB.name);
              }
              return 0;
            })
            .map((site) => {
              const newSite = site;
              newSite.devices = site.devices.sort((parkinglotA, parkinglotB) => {
                // Dont compare if null
                if (parkinglotA.name && parkinglotB.name) {
                  return parkinglotA.name.localeCompare(parkinglotB.name);
                }
                return 0;
              });

              newSite.barriers = site.barriers.sort((barriersA, barriersB) => {
                // Dont compare if null
                if (barriersA.name && barriersB.name) {
                  return barriersA.name.localeCompare(barriersB.name);
                }
                return 0;
              });
              return newSite;
            });
        });
      Vue.set(state, "zones", zones);
    },
    setZoneID: (state, zoneID) => Vue.set(state, "zoneID", zoneID),
    setLoadingState: (state, loading) => Vue.set(state, "loading", loading),
    setErrorGetParkingLots: (state, errorGetParkingLots) =>
      Vue.set(state, "errorGetParkingLots", errorGetParkingLots),
    setEditing: (state, editing) => Vue.set(state, "editing", editing),
    setErrorEditingItem: (state, errorEditingItem) =>
      Vue.set(state, "errorEditingItem", errorEditingItem),
    setSuccessEditingItem: (state, successEditingItem) =>
      Vue.set(state, "successEditingItem", successEditingItem),
    setRebootResponseCode: (state, rebootResponseCode) =>
      Vue.set(state, "rebootResponseCode", rebootResponseCode),
    setRefreshResponseCode: (state, refreshResponseCode) => {
      Vue.set(state, "refreshResponseCode", refreshResponseCode);
      console.log("Updating store");
    },
    setRecalibrateResponseCode: (state, recalibrateResponseCode) =>
      Vue.set(state, "recalibrateResponseCode", recalibrateResponseCode),
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  actions: {
    /**
     * updates all zones
     * @param context used by store
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchZones: (context) => {
      context.commit("setLoadingState", true);
      context.commit("setErrorGetParkingLots", false);
      return AxiosRestService.get("/zones/dashboard")
        .then((response) => {
          context.commit("setZones", response.data);
          context.commit("setLoadingState", false);
        })
        .catch(() => {
          context.commit("setErrorGetParkingLots", true);
          context.commit("setLoadingState", false);
        });
    },
    /**
     * Add Device
     * @param context used by store
     * @param item Parkinglot or Barrier which should be edited
     * @returns {Promise<unknown>}
     */
    addDevice: (context, item) => {
      context.commit("setEditing", true);
      context.commit("setErrorEditingItem", false);
      context.commit("setZoneID", item.zoneID);
      return AxiosRestService.post("/devices/add", item)
        .then(() => {
          context.dispatch("fetchZones", "setSuccessEditingItem");
          context.commit("setErrorEditingItem", false);
        })
        .catch(() => {
          context.commit("setErrorEditingItem", true);
          context.commit("setSuccessEditingItem", false);
        })
        .finally(() => {
          context.commit("setEditing", false);
          context.dispatch("fetchZones");
        });
    },
    /**
     * Edit Device
     * @param context used by store
     * @param item Parkinglot or Barrier which should be edited
     * @returns {Promise<unknown>}
     */
    editDevice: (context, item) => {
      context.commit("setEditing", true);
      context.commit("setErrorEditingItem", false);
      context.commit("setZoneID", item.zoneID);
      return AxiosRestService.post(`/devices/edit/${item.id}`, item)
        .then(() => {
          context.dispatch("fetchZones", "setSuccessEditingItem");
          context.commit("setErrorEditingItem", false);
        })
        .catch(() => {
          context.commit("setErrorEditingItem", true);
          context.commit("setSuccessEditingItem", false);
        })
        .finally(() => {
          context.commit("setEditing", false);
          context.dispatch("fetchZones");
        });
    },

    /**
     * edit selected Barrier
     * @param context used by store
     * @param item Parkinglot or Barrier which should be edited
     * @returns {Promise<unknown>}
     */
    editBarrier: (context, item) => {
      context.commit("setEditing", true);
      context.commit("setErrorEditingItem", false);
      context.commit("setZoneID", item.zoneID);
      return AxiosRestService.patch(`/barriers/edit/${item.id}`, item)
        .then(() => {
          context.dispatch("fetchZones", "setSuccessEditingItem");
          context.commit("setErrorEditingItem", false);
        })
        .catch(() => {
          context.commit("setErrorEditingItem", true);
          context.commit("setSuccessEditingItem", false);
        })
        .finally(() => {
          context.commit("setEditing", false);
          context.dispatch("fetchZones");
        });
    },
    /**
     * edit selected Barrier
     * @param context used by store
     * @param item Parkinglot or Barrier which should be edited
     * @returns {Promise<unknown>}
     */
    addBarrier: (context, item) => {
      context.commit("setEditing", true);
      context.commit("setErrorEditingItem", false);
      context.commit("setZoneID", item.zoneID);
      return AxiosRestService.patch("/barriers/add", item)
        .then(() => {
          context.dispatch("fetchZones", "setSuccessEditingItem");
          context.commit("setErrorEditingItem", false);
        })
        .catch(() => {
          context.commit("setErrorEditingItem", true);
          context.commit("setSuccessEditingItem", false);
        })
        .finally(() => {
          context.commit("setEditing", false);
          context.dispatch("fetchZones");
        });
    },

    /**
     * Sends the refresh request to the device
     * @param context Used by store
     * @param id The database id of the device.
     * @param timeout The device status timeout in seconds
     */
    async sendRefresh(context, { id, timeout }) {
      return sendCommand(id, timeout, State.UPDATING_STATUS)
        .then((response) => {
          context.commit("setRefreshResponseCode", response.status);
          return response.data.data.attributes.status;
        })
        .catch((error) => {
          context.commit("setRefreshResponseCode", error.response.status);
          return State.ERROR;
        });
    },

    /**
     * Sends the recalibrate request to the device
     * @param context Used by store
     * @param id The database id of the device.
     * @param timeout The device status timeout in seconds
     */
    async sendRecalibrate(context, { id, timeout }) {
      await sendCommand(id, timeout, State.RECALIBRATE)
        .then((response) => {
          context.commit("setRecalibrateResponseCode", response.status);
        })
        .catch((error) => {
          context.commit("setRecalibrateResponseCode", error.response.status);
        });
    },

    /**
     * Sends the reboot request to the device
     * @param context Used by store
     * @param id The database id of the device.
     * @param timeout The device status timeout in seconds
     */
    async sendReboot(context, { id, timeout }) {
      await sendCommand(id, timeout, State.REBOOT)
        .then((response) => {
          context.commit("setRebootResponseCode", response.status);
        })
        .catch((error) => {
          context.commit("setRebootResponseCode", error.response.status);
        });
    },

    getIssues: (context) =>
      AxiosRestService.get("/devices/databaseProblems").then((response) =>
        context.commit("setIssues", Object.values(response.data)),
      ),
  },
};
