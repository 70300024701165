export function singularize(word: string) {
  const endings = {
    ves: "fe",
    ies: "y",
    i: "us",
    zes: "ze",
    ses: "s",
    es: "e",
    s: "",
  };
  return word.replace(
    new RegExp(`(${Object.keys(endings).join("|")})$`),
    (r) => endings[r as keyof typeof endings],
  );
}

export function isSingular(word: string) {
  return singularize(word) === word;
}
