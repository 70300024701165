import Vue from "vue";
import AxiosRestService from "@/network/axiosRestService";

const initialState = () => ({
  index: [],
  loading: false,

  editing: false,
  adding: false,
  deleting: false,
  errorLoadingIndex: false,
  errorDeletingItem: false,
  errorEditingItem: false,
  errorAddingItem: false,
  successDeletingItem: false,
  successEditingItem: false,
  successAddingItem: false,
  tenantId: 0,
});

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    index: (state) => state.index,
    loading: (state) => state.loading,
    editing: (state) => state.editing,
    deleting: (state) => state.deleting,
    adding: (state) => state.adding,
    errorLoadingIndex: (state) => state.errorLoadingIndex,
    errorEditingItem: (state) => state.errorEditingItem,
    errorDeletingItem: (state) => state.errorDeletingItem,
    errorAddingItem: (state) => state.errorAddingItem,
    successEditingItem: (state) => state.successEditingItem,
    successDeletingItem: (state) => state.successDeletingItem,
    successAddingItem: (state) => state.successAddingItem,
    tenantId: (state) => state.tenantId,
  },
  // -----------------------------------------------------------------
  mutations: {
    setIndex: (state, tenants) => Vue.set(state, "index", tenants),
    setLoading: (state, loading) => Vue.set(state, "loading", loading),
    setEditing: (state, editing) => Vue.set(state, "editing", editing),
    setDeleting: (state, deleting) => Vue.set(state, "deleting", deleting),
    setAdding: (state, adding) => Vue.set(state, "adding", adding),
    setErrorLoadingIndex: (state, errorLoadingIndex) =>
      Vue.set(state, "errorLoadingIndex", errorLoadingIndex),
    setErrorEditingItem: (state, errorEditingItem) =>
      Vue.set(state, "errorEditingItem", errorEditingItem),
    setErrorDeletingItem: (state, errorDeletingItem) =>
      Vue.set(state, "errorDeletingItem", errorDeletingItem),
    setErrorAddingItem: (state, errorAddingItem) =>
      Vue.set(state, "errorAddingItem", errorAddingItem),
    setSuccessEditingItem: (state, successEditingItem) =>
      Vue.set(state, "successEditingItem", successEditingItem),
    setSuccessDeletingItem: (state, successDeletingItem) =>
      Vue.set(state, "successDeletingItem", successDeletingItem),
    setSuccessAddingItem: (state, successAddingItem) =>
      Vue.set(state, "successAddingItem", successAddingItem),
    setTenantId: (state, tenantId) => Vue.set(state, "tenantId", tenantId),

    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  // -----------------------------------------------------------------
  actions: {
    loadIndex: (context) => {
      if (!context.rootGetters.user.isAdmin()) {
        return [];
      }

      context.commit("setLoading", true);
      return AxiosRestService.get("/tenants")
        .then((response) => {
          context.commit("setIndex", response.data);
        })
        .finally(() => context.commit("setLoading", false));
    },
    addItem: (context, item) => {
      context.commit("setAdding", true);
      return AxiosRestService.post("/tenants/add_ajax", item)
        .then(() => {
          context.dispatch("loadIndex");
          context.commit("setErrorAddingItem", false);
        })
        .catch(() => {
          context.commit("setErrorAddingItem", true);
          context.commit("setSuccessAddingItem", false);
        })
        .finally(() => context.commit("setAdding", false));
    },
    editItem: (context, item) => {
      context.commit("setEditing", true);
      return AxiosRestService.patch(`/tenants/edit_ajax/${item.id}`, item)
        .then(() => {
          context.dispatch("loadIndex");
          context.dispatch("setSuccessEditingItem");
          context.commit("setErrorEditingItem", false);
        })
        .catch(() => {
          context.commit("setErrorEditingItem", true);
          context.commit("setSuccessEditingItem", false);
        })
        .finally(() => context.commit("setEditing", false));
    },

    getTenantIdForPrefix: (context, prefix) => {
      return new Promise((resolve, reject) =>
        AxiosRestService.post("/tenants/getTenantIdFromPrefix/", {
          tenant_prefix: prefix,
        })
          .then((response) => {
            context.commit("setTenantId", response.data.id);
            resolve();
          })
          .catch(() => {
            reject();
          }),
      );
    },
  },
};
