import { DeviceType } from "@/model/deviceType";

export enum State {
  FREE = "free",
  FREEING = "freeing",
  BLOCKED = "blocked",
  BLOCKING = "blocking",
  OCCUPIED = "occupied",
  RECALIBRATE = "recalibrate",
  REBOOT = "reboot",
  UPDATING_STATUS = "updating_status",
  ERROR = "error",
}

class Device {
  public id: number;

  public name: string;

  public status: State;

  public type: DeviceType;

  public isOptional: boolean;

  public siteId: number;

  constructor(
    id: number,
    name: string,
    status: State,
    type: string,
    isOptional: boolean,
    siteId: number,
  ) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.type = new DeviceType(type);
    this.isOptional = isOptional;
    this.siteId = siteId;
  }
}

export default Device;
