import Role, { Roles } from "./role";
import Zone from "./zone";
import Model from "./model";
import Tenant from "./tenant";

export enum UserState {
  ACTIVE,
}

export enum Gender {
  MALE,
  FEMALE,
  OTHER,
}

export enum Type {
  SUPER = "SUPER",
}

export interface UserInterface extends Model {
  username: string;
  accountBalance: number;
  created: Date;
  modified: Date;
  email: string;
  mobileNumber: string;
  landlineNumber: string;
  firstname: string;
  lastname: string;
  title: string;
  gender: Gender;
  userState: UserState;
  addressLine1: string;
  zip: string;
  AGB: boolean;
  type: Type;
  role: Role;
  zones: Zone[];
  features: string[];
  announcements: [];
  fullName: string;
  tenant: Tenant;
  rmContactEmail: string;
  rmContactPhoneNumber: string;
  city?: string;
  licencePlate: string;
  additionalSupportInfo: string;
  language: string;

  isAdmin(): boolean;

  isReservationManager(): boolean;

  isSuperReservationManager(): boolean;

  isVisitor(): boolean;

  getRoleName(): string;
}

export default class User implements UserInterface {
  private constructor(
    public id: number,
    public username: string,
    public accountBalance: number,
    public created: Date,
    public modified: Date,
    public email: string,
    public mobileNumber: string,
    public landlineNumber: string,
    public firstname: string,
    public lastname: string,
    public title: string,
    public gender: Gender,
    public userState: UserState,
    public addressLine1: string,
    public zip: string,
    public AGB: boolean,
    public type: Type,
    public role: Role,
    public zones: Zone[],
    public features: string[],
    public announcements: [],
    public fullName: string,
    public tenant: Tenant,
    public sessionTenant: undefined,
    public rmContactEmail: string,
    public rmContactPhoneNumber: string,
    public language: string,
    public licencePlate: string,
    public additionalSupportInfo: string,
    public city?: string | undefined,
  ) {}

  public static createInstanceFromObject(user: User) {
    return new this(
      user.id,
      user.username,
      user.accountBalance,
      user.created,
      user.modified,
      user.email,
      user.mobileNumber,
      user.landlineNumber,
      user.firstname,
      user.lastname,
      user.title,
      user.gender,
      user.userState,
      user.addressLine1,
      user.zip,
      user.AGB,
      user.type,
      user.role,
      user.zones,
      user.features,
      user.announcements,
      user.fullName,
      user.tenant,
      user.sessionTenant,
      user.rmContactEmail,
      user.rmContactPhoneNumber,
      user.language.toLowerCase(),
      user.licencePlate,
      user.additionalSupportInfo,
      user.city,
    );
  }

  public static createInstance(
    id: number,
    username: string,
    accountBalance: number,
    created: Date,
    modified: Date,
    email: string,
    mobileNumber: string,
    landlineNumber: string,
    firstname: string,
    lastname: string,
    title: string,
    gender: Gender,
    userState: UserState,
    addressLine1: string,
    zip: string,
    AGB: boolean,
    type: Type,
    role: Role,
    zones: Zone[],
    features: string[],
    announcements: [],
    fullName: string,
    tenant: Tenant,
    sessionTenant: undefined,
    rmContactEmail: string,
    rmContactPhoneNumber: string,
    language: string,
    licencePlate: string,
    additionalSupportInfo: string,
    city?: string | undefined,
  ) {
    return new this(
      id,
      username,
      accountBalance,
      created,
      modified,
      email,
      mobileNumber,
      landlineNumber,
      firstname,
      lastname,
      title,
      gender,
      userState,
      addressLine1,
      zip,
      AGB,
      type,
      role,
      zones,
      features,
      announcements,
      fullName,
      tenant,
      sessionTenant,
      rmContactEmail,
      rmContactPhoneNumber,
      language.toLowerCase(),
      licencePlate,
      additionalSupportInfo,
      city,
    );
  }

  isAdmin() {
    return this.role.name === Roles.ADMIN;
  }

  isReservationManager(): boolean {
    return this.role.name === Roles.RESERVATION_MANAGER;
  }

  isSuperReservationManager(): boolean {
    return this.role.name === Roles.RESERVATION_MANAGER && this.type === Type.SUPER;
  }

  isVisitor(): boolean {
    return this.role.name === Roles.VISITOR;
  }

  isSupport(): boolean {
    return this.role.name === Roles.SUPPORT;
  }

  getRoleName(): string {
    return this.role.name;
  }
}
