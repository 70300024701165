export enum Languages {
  EN = "en",
  DE = "de",
  IT = "it",
  FR = "fr",
}

export class LanguagePayloads {
  // Helper function
  private static payloadFactory(lang: string, cookieLang = "", acceptedLang = "") {
    return { lang, cookieLang, acceptedLang };
  }

  /**
   * Generates a payload that is used in the language store (inside languages/translateTo)
   * If a language's cookie value or accepted language value differs from the 2-letter language code
   * it must be added into the switch statement as a special case
   * @param lang The 2-letter language code in lower case
   * @returns An object that contains the payload parameters for the language store
   */
  static get(lang: string) {
    let payload;
    switch (lang) {
      case Languages.EN:
        // For english the cookie and accepted languages differ from the 2-letter language code
        payload = this.payloadFactory(Languages.EN, "en_US", "en-US");
        break;
      default:
        payload = this.payloadFactory(lang);
    }

    return payload;
  }
}
