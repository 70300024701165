import User from "@/model/user";
import Zone from "@/model/zone";
import Response from "@/network/response";
import RestService from "../restService";
import CRUDRepository from "./crudRepository";

type LoginResponse = {
  meta?: { sessionTenant: never };
  data: {
    id: string;
    attributes: {
      username: string;
      accountBalance: number;
      created: string;
      modified: string;
      email: string;
      mobileNumber: string;
      landlineNumber: string;
      firstname: string;
      lastname: string;
      title: string;
      gender: string;
      addressLine1: string;
      zip: string;
      agb: string;
      type: string;
      fullName: string;
      rmContactEmail: string;
      rmContactPhoneNumber: string;
      licencePlate: string;
      city: string;
      language: string;
    };
    relationships: {
      zones: { data: Array<{ id: string }> };
      tenants: { data: Array<{ id: string }> };
      roles: { data: Array<{ id: string }> };
    };
  };
  included: Array<{
    type: "zones" | "tenants" | "roles";
    id: string;
    attributes: Record<string, never>;
  }>;
};
export default class UserRepository extends CRUDRepository<User> {
  private static readonly LOGIN_URL = "/users/login";

  private static readonly ANNOUNCEMENTS_URL = "/announcements/currentlyActive";

  private static readonly PRODUCTS_URL = "/users/me/products";

  private static readonly LOGOUT_URL = "/users/logout";

  private static readonly RESET_PASSWORD_URL = "/users/password_reset_request";

  private static readonly PROFILE_URL = "/users/profile";

  private static readonly BASE_URL = "/users";

  private static readonly ADD_URL = "/users/add";

  private static readonly EDIT_URL = "/users/edit";

  private static readonly DELETE_URL = "/users/delete";

  private static readonly SEARCH_URL = "/users/filter_ajax";

  private static readonly INVITE_RM_URL = "/users/rm_invitation";

  private static readonly REMOVE_RM_URL = "/users/remove_rm";

  private static readonly CHECK_TOKEN_URL = "/users/getUserEmailWithRegisterToken";

  private static readonly CHECK_INVITE_TOKEN_URL = "/users/getUserEmailFromInvitationToken";

  private static readonly ACCOUNT_BALANCE_URL = "/users/getAccountBalanceForCurrentUser";

  private static readonly REGISTER_URL = "/users/register";

  private static readonly ME_URL = "/users/me";

  private config = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  constructor(service: RestService) {
    super(
      service,
      UserRepository.BASE_URL,
      UserRepository.ADD_URL,
      UserRepository.EDIT_URL,
      UserRepository.DELETE_URL,
    );
  }

  async login(credentials: { username: string; password: string }) {
    const data = {
      data: {
        type: "users",
        attributes: credentials,
      },
    };

    const loginResponse = await this.service.post<LoginResponse>(
      `${UserRepository.LOGIN_URL}?include=roles,tenants,zones`,
      data,
      this.config,
    );
    const announcementsResponse = await this.service.get<never>(UserRepository.ANNOUNCEMENTS_URL);
    const productsResponse = await this.service.get<{
      data: Array<{ attributes: { prefix: string } }>;
    }>(UserRepository.PRODUCTS_URL, this.config);

    const userObject = UserRepository.parseLoginResponse(
      loginResponse,
      announcementsResponse,
      productsResponse,
    );

    return userObject;
  }

  static parseLoginResponse(
    loginResponse: Response<LoginResponse>,
    announcementsResponse: Response<never>,
    productsResponse: Response<{ data: Array<{ attributes: { prefix: string } }> }>,
  ) {
    const includes = loginResponse.data.included;

    // *** sessionTenant ***
    const { sessionTenant } = loginResponse.data.meta ?? { sessionTenant: null };

    // *** announcements ***
    const announcements = announcementsResponse.data;

    // *** features ***
    const productsList = productsResponse.data.data;
    const features = productsList.map((el) => el.attributes.prefix);

    // *** zones ***
    const userZonesArray = loginResponse.data.data.relationships.zones.data;
    const zoneIds = userZonesArray.map((el) => el.id);
    const zonesIncludes = includes.filter((el) => el.type === "zones" && zoneIds.includes(el.id));
    const zones = zonesIncludes.map((el) => ({
      ...el.attributes,
      id: parseInt(el.id, 10),
    }));

    // *** tenant ***
    const userTenantsArray = loginResponse.data.data.relationships.tenants.data;
    const tenantIds = userTenantsArray.map((el) => el.id);
    const tenantIncludes = includes.filter(
      (el) => el.type === "tenants" && tenantIds.includes(el.id),
    );
    const tenant =
      tenantIncludes
        .map((el) => ({
          ...el.attributes,
          id: parseInt(el.id, 10),
        }))
        .at(0) ?? {};

    // *** roles ***
    const userRolesArray = loginResponse.data.data.relationships.roles.data;
    const userRolesIds = userRolesArray.map((el) => el.id);
    const rolesIncludes = includes.filter(
      (el) => el.type === "roles" && userRolesIds.includes(el.id),
    );
    const roles =
      rolesIncludes
        .map((el) => ({
          ...el.attributes,
          id: parseInt(el.id, 10),
        }))
        .at(0) ?? {};

    // *** user ***
    const userAttributes = loginResponse.data.data.attributes;
    return {
      id: loginResponse.data.data.id,
      username: userAttributes.username,
      accountBalance: userAttributes.accountBalance,
      created: userAttributes.created,
      modified: userAttributes.modified,
      email: userAttributes.email,
      mobileNumber: userAttributes.mobileNumber,
      landlineNumber: userAttributes.landlineNumber,
      firstname: userAttributes.firstname,
      lastname: userAttributes.lastname,
      title: userAttributes.title,
      gender: userAttributes.gender,
      addressLine1: userAttributes.addressLine1,
      zip: userAttributes.zip,
      AGB: userAttributes.agb,
      type: userAttributes.type,
      role: roles,
      zones,
      features,
      announcements,
      fullName: userAttributes.fullName,
      tenant,
      sessionTenant,
      rmContactEmail: userAttributes.rmContactEmail,
      rmContactPhoneNumber: userAttributes.rmContactPhoneNumber,
      language: userAttributes.language.toLowerCase(),
      licencePlate: userAttributes.licencePlate,
      city: userAttributes.city,
    };
  }

  async logout() {
    await this.service.post(UserRepository.LOGOUT_URL, {}, this.config);
  }

  resetPassword(email: string) {
    return this.service.post(UserRepository.RESET_PASSWORD_URL, email);
  }

  async getProfile() {
    const user = await this.service.get<User>(UserRepository.PROFILE_URL);
    return user.data;
  }

  async updateProfile(user: User) {
    await this.service.patch(UserRepository.PROFILE_URL, user);
  }

  async search(query: string) {
    const response = await this.service.get<User>(`${UserRepository.SEARCH_URL}?search=${query}`);
    return response.data;
  }

  async inviteToBecomeReservationManager(data: { email: string; zones: Zone[] }) {
    await this.service.post(UserRepository.INVITE_RM_URL, data);
  }

  async removeReservationManagerRights(user: User) {
    const response = await this.service.post<User[]>(UserRepository.REMOVE_RM_URL, {
      userId: user.id,
    });
    return response.data;
  }

  async checkInvitationToken(token: string) {
    const response = await this.service.get<string>(
      `${UserRepository.CHECK_INVITE_TOKEN_URL}/${token}`,
    );
    return response.data;
  }

  async getAccountBalance() {
    const response = await this.service.get<string>(`${UserRepository.ACCOUNT_BALANCE_URL}`);
    return response.data;
  }

  async register(user: { email: string; password: string; AGB: boolean; language: string }) {
    const response = await this.service.post<
      { data: { id: string } } | { "error-code": string; message: string }
    >(UserRepository.REGISTER_URL, user);
    return response.data;
  }

  async me() {
    const response = await this.service.get<LoginResponse>(
      `${UserRepository.ME_URL}?include=roles,tenants,zones`,
      this.config,
    );
    const announcementsResponse = await this.service.get<never>(UserRepository.ANNOUNCEMENTS_URL);
    const productsResponse = await this.service.get<{
      data: Array<{ attributes: { prefix: string } }>;
    }>(UserRepository.PRODUCTS_URL, this.config);
    return UserRepository.parseLoginResponse(response, announcementsResponse, productsResponse);
  }
}
