import Vue from "vue";
import i18n from "@/i18n";
import { parseError } from "@/api/ErrorParser";
import AxiosRestService from "@/network/axiosRestService";
import Notification from "@/model/notification";

const initialState = () => ({
  user: {},
  notificationSettings: {},
  updatingNotificationSettings: false,
  contactInformation: {},
  loading: false,
  updatingAccountInformation: false,
  changingPassword: false,
  updatingNotifications: false,
  updatingAddress: false,
  updatingVwOidc: false,
  updatingContactInformation: false,
  updatingLicencePlate: false,
  errors: {},
});

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    item: (state) => state.user,
    notificationSettings: (state) => state.notificationSettings,
    updatingNotificationSettings: (state) => state.updatingNotificationSettings,
    contactInformation: (state) => state.contactInformation,
    updatingAccountInformation: (state) => state.updatingAccountInformation,
    changingPassword: (state) => state.changingPassword,
    updatingNotifications: (state) => state.updatingNotifications,
    updatingAddress: (state) => state.updatingAddress,
    updatingVwOidc: (state) => state.updatingVwOidc,
    updatingContactInformation: (state) => state.updatingContactInformation,
    licencePlate: (state) => state.user.licence_plate,
    updatingLicencePlate: (state) => state.updatingLicencePlate,
    allowsAutomaticAccess: (state) => state.user.allows_automatic_access,
    errorsUpdatingPortfolio: (state) => state.errors,
  },
  // -----------------------------------------------------------------
  mutations: {
    setUser: (state, user) => Vue.set(state, "user", user),
    setNotificationSettings: (state, notificationSettings) =>
      Vue.set(state, "notificationSettings", notificationSettings),
    setUpdatingNotificationSettings: (state, value) => {
      state.updatingNotificationSettings = value;
    },
    setContactInformation: (state, contactInformation) =>
      Vue.set(state, "contactInformation", contactInformation),
    setUpdatingAccountInformation: (state, value) => {
      state.updatingAccountInformation = value;
    },
    setChangingPassword: (state, value) => {
      state.changingPassword = value;
    },
    setUpdatingNotifications: (state, value) => {
      state.updatingNotifications = value;
    },
    setUpdatingAddress: (state, value) => {
      state.updatingAddress = value;
    },
    setUpdatingVwOidc: (state, value) => {
      state.updatingVwOidc = value;
    },
    setUpdatingLicencePlate: (state, value) => {
      state.updatingLicencePlate = value;
    },
    setUpdatingContactInformation: (state, value) => {
      state.updatingContactInformation = value;
    },
    setErrorsUpdatingPortfolio: (state, errors) => {
      state.errors = errors;
    },
    resetProfileError: (state, field) => {
      state.errors[field] = [];
    },
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  // -----------------------------------------------------------------
  actions: {
    async getProfile(context) {
      const profile = await AxiosRestService.get("/users/profile");
      context.commit("setUser", profile.data);
    },
    async getNotificationSettings(context) {
      const notificationSettings = await AxiosRestService.get("/notificationSettings/update");
      context.commit("setNotificationSettings", notificationSettings.data);
    },
    async updateNotificationSettings(context, data) {
      context.commit("setUpdatingNotificationSettings", true);
      await context.dispatch("updateNotificationSettingsData", data);
      context.commit("setUpdatingNotificationSettings", false);
    },
    async getContactInformation(context, id) {
      const contactInformation = await AxiosRestService.get(`/users/getRMContactInformation/${id}`);
      context.commit("setContactInformation", contactInformation.data);
    },
    async updateAccountInformation(context, data) {
      context.commit("setUpdatingAccountInformation", true);
      await context.dispatch("updateProfile", data);
      context.commit("setUpdatingAccountInformation", false);
    },
    async changePassword(context, data) {
      context.commit("setChangingPassword", true);
      await context.dispatch("updateProfile", data); // TODO implement
      context.commit("setChangingPassword", false);
    },
    async updateNotifications(context, data) {
      context.commit("setUpdatingNotifications", true);
      await context.dispatch("updateProfile", data);
      context.commit("setUpdatingNotifications", false);
    },
    async updateAddress(context, data) {
      context.commit("setUpdatingAddress", true);
      await context.dispatch("updateProfile", data);
      context.commit("setUpdatingAddress", false);
    },
    async updateLicencePlate(context, data) {
      context.commit("setUpdatingLicencePlate", true);
      await context.dispatch("updateProfile", data);
      context.commit("setUpdatingLicencePlate", false);
      context.dispatch("updateLicencePlate", context.getters.licencePlate, { root: true });
    },
    async updateVwOidc(context, data) {
      context.commit("setUpdatingVwOidc", true);
      await context.dispatch("updateProfile", data);
      context.commit("setUpdatingVwOidc", false);
      context.commit("oidcRegister/setShowVwBnPMappingDialogue", false, { root: true });
      context.commit("oidcRegister/setVwBnPMappingDialogueSuccess", true, { root: true });
    },
    async updateContactInformation(context, data) {
      context.commit("setUpdatingContactInformation", true);
      context.commit("setErrorsUpdatingPortfolio", {});

      try {
        await AxiosRestService.put(`/users/updateRMContactInformation/${data.id}`, {
          rm_contact_email: data.email,
          rm_contact_phone_number: data.phoneNumber,
        });
        await context.dispatch("getContactInformation", data.id);
        await context.dispatch(
          "notifications/success",
          new Notification(i18n.t("Your profile was updated successfully")),
          { root: true },
        );
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const errorObject = error.response.data;
          context.commit("setErrorsUpdatingPortfolio", parseError(errorObject));
          context.commit("setUpdatingContactInformation", false);
          return;
        }
        await context.dispatch(
          "notifications/error",
          new Notification(i18n.t("error-updating-profile")),
          { root: true },
        );
      }
      context.commit("setUpdatingContactInformation", false);
    },
    async updateProfile(context, data) {
      context.commit("setErrorsUpdatingPortfolio", {});
      try {
        await AxiosRestService.patch("/users/profile", data);
        await context.dispatch("getProfile");
        await context.dispatch(
          "notifications/success",
          new Notification(i18n.t("Your profile was updated successfully")),
          { root: true },
        );
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const errorObject = error.response.data;
          context.commit("setErrorsUpdatingPortfolio", parseError(errorObject));
          return;
        }
        await context.dispatch(
          "notifications/error",
          new Notification(i18n.t("error-updating-profile")),
          { root: true },
        );
      }
    },
    async updateNotificationSettingsData(context, data) {
      context.commit("setErrorsUpdatingPortfolio", {});
      try {
        await AxiosRestService.post("/notificationSettings/update", data);
        await context.dispatch("getProfile");
        await context.dispatch(
          "notifications/success",
          new Notification(i18n.t("Your profile was updated successfully")),
          { root: true },
        );
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const errorObject = error.response.data;
          context.commit("setErrorsUpdatingPortfolio", parseError(errorObject));
          return;
        }
        await context.dispatch(
          "notifications/error",
          new Notification(i18n.t("error-updating-profile")),
          { root: true },
        );
      }
    },
    resetProfileError(context, field) {
      context.commit("resetProfileError", field);
    },
    async updateProfileDuringReservation(context, data) {
      context.commit("setErrorsUpdatingPortfolio", {});
      try {
        await AxiosRestService.patch("/users/profile", data);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const errorObject = error.response.data;
          context.commit("setErrorsUpdatingPortfolio", parseError(errorObject));
          return;
        }
        await context.dispatch(
          "notifications/error",
          new Notification(i18n.t("error-updating-profile-during-reservation")),
          { root: true },
        );
      }
    },
  },
};
