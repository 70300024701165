import Vue from "vue";
import AxiosRestService from "@/network/axiosRestService";
import Notification from "@/model/notification";
import i18n from "@/i18n";

const initialState = () => ({
  index: [],
  issues: [],
  loading: false,
  editing: false,
  adding: false,
  deleting: false,
  errorLoadingIndex: false,
  errorDeletingItem: false,
  errorEditingItem: false,
  errorAddingItem: false,
  successDeletingItem: false,
  successEditingItem: false,
  successAddingItem: false,
});

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    index: (state) => state.index,
    issues: (state) => state.issues,
    loading: (state) => state.loading,
    editing: (state) => state.editing,
    deleting: (state) => state.deleting,
    adding: (state) => state.adding,
    errorLoadingIndex: (state) => state.errorLoadingIndex,
    errorEditingItem: (state) => state.errorEditingItem,
    errorDeletingItem: (state) => state.errorDeletingItem,
    errorAddingItem: (state) => state.errorAddingItem,
    successEditingItem: (state) => state.successEditingItem,
    successDeletingItem: (state) => state.successDeletingItem,
    successAddingItem: (state) => state.successAddingItem,
  },
  // -----------------------------------------------------------------
  mutations: {
    setIndex: (state, roles) => Vue.set(state, "index", roles),
    setIssues: (state, issues) => Vue.set(state, "issues", issues),
    setLoading: (state, loading) => Vue.set(state, "loading", loading),
    setEditing: (state, editing) => Vue.set(state, "editing", editing),
    setDeleting: (state, deleting) => Vue.set(state, "deleting", deleting),
    setAdding: (state, adding) => Vue.set(state, "adding", adding),
    setErrorLoadingIndex: (state, errorLoadingIndex) =>
      Vue.set(state, "errorLoadingIndex", errorLoadingIndex),
    setErrorEditingItem: (state, errorEditingItem) =>
      Vue.set(state, "errorEditingItem", errorEditingItem),
    setErrorDeletingItem: (state, errorDeletingItem) =>
      Vue.set(state, "errorDeletingItem", errorDeletingItem),
    setErrorAddingItem: (state, errorAddingItem) =>
      Vue.set(state, "errorAddingItem", errorAddingItem),
    setSuccessEditingItem: (state, successEditingItem) =>
      Vue.set(state, "successEditingItem", successEditingItem),
    setSuccessDeletingItem: (state, successDeletingItem) =>
      Vue.set(state, "successDeletingItem", successDeletingItem),
    setSuccessAddingItem: (state, successAddingItem) =>
      Vue.set(state, "successAddingItem", successAddingItem),
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  // -----------------------------------------------------------------
  actions: {
    loadIndex: (context) => {
      context.commit("setLoading", true);
      return AxiosRestService.get("/users")
        .then((response) => {
          context.commit("setIndex", response.data);
          context.commit("setErrorLoadingIndex", false);
        })
        .catch(() => context.commit("setErrorLoadingIndex", true))
        .finally(() => context.commit("setLoading", false));
    },
    addItem: (context, item) => {
      context.commit("setAdding", true);
      return AxiosRestService.post("/users/add", item)
        .then(() => {
          context.dispatch("loadIndex");
          context.dispatch("setSuccessStatus", "setSuccessAddingItem");
          context.commit("setErrorAddingItem", false);
        })
        .catch(() => {
          context.commit("setErrorAddingItem", true);
          context.commit("setSuccessAddingItem", false);
        })
        .finally(() => context.commit("setAdding", false));
    },
    inviteRM: (context, item) => {
      context.commit("setAdding", true);
      return AxiosRestService.post("/users/rm_invitation", item)
        .then((response) => {
          context.dispatch("loadIndex");
          context.dispatch("setSuccessStatus", "setSuccessAddingItem");
          context.commit("setErrorAddingItem", false);
          context
            .dispatch(
              "notifications/success",
              new Notification(String(i18n.t(response.data.msg))),
              { root: true },
            )
            .finally(() => Promise.resolve(response));

          return Promise.resolve(response);
        })
        .catch((error) => {
          context.commit("setErrorAddingItem", true);
          context.commit("setSuccessAddingItem", false);
          context
            .dispatch(
              "notifications/error",
              new Notification(String(i18n.t(error.response.data.msg))),
              { root: true },
            )
            .finally(() => Promise.reject(error));

          return Promise.reject(error);
        })
        .finally(() => context.commit("setAdding", false));
    },
    editItem: (context, item) => {
      context.commit("setEditing", true);
      return AxiosRestService.patch(`/users/edit/${item.id}`, item)
        .then(() => {
          context.dispatch("loadIndex");
          context.dispatch("setSuccessStatus", "setSuccessEditingItem");
          context.commit("setErrorEditingItem", false);
        })
        .catch(() => {
          context.commit("setErrorEditingItem", true);
          context.commit("setSuccessEditingItem", false);
        })
        .finally(() => context.commit("setEditing", false));
    },
    deleteItem: (context, userId) => {
      context.commit("setDeleting", true);
      return AxiosRestService.delete(`/users/delete/${userId}`)
        .then(() => {
          context.dispatch("loadIndex");
          context.dispatch("setSuccessStatus", "setSuccessDeletingItem");
          context.commit("setErrorDeletingItem", false);
        })
        .catch(() => {
          context.commit("setErrorDeletingItem", true);
          context.commit("setSuccessDeletingItem", false);
        })
        .finally(() => context.commit("setDeleting", false));
    },
    setSuccessStatus: (context, statusSetter) => {
      context.commit(statusSetter, true);
      setTimeout(() => context.commit(statusSetter, false), 3000);
    },
    deleteRMRights: (context, payload) =>
      AxiosRestService.post("/users/remove_rm", payload).then((response) => {
        context.dispatch("loadIndex");
        return response.data;
      }),
    fetchUserByParameter: (context, searchString) => {
      context.commit("setLoading", true);
      return AxiosRestService.get(`/users/filter_ajax/${searchString}`).then((response) => {
        context.commit("setLoading", false);
        context.commit("setIndex", response.data);
      });
    },
    sendPasswordMail: (context, email) =>
      AxiosRestService.post("/users/password_reset_request", {
        email,
      }),
    resetPassword: (context, email) =>
      AxiosRestService.post("/users/password_reset_request", {
        email,
      })
        .then((response) => response.message)
        .catch((error) => error.data.message),

    getIssues: (context) =>
      AxiosRestService.get("/users/databaseProblems").then((response) =>
        context.commit("setIssues", Object.values(response.data)),
      ),
  },
};
