<template>
  <div class="fill-height">
    <slot></slot>
    <offline-notification></offline-notification>
    <alert-pool style="z-index: 99999999"></alert-pool>
  </div>
</template>

<script>
import AlertPool from "@/components/AlertPool.vue";
import OfflineNotification from "@/components/OfflineNotificationComponent.vue";

export default {
  name: "FullscreenLayout",
  components: {
    AlertPool,
    OfflineNotification,
  },
  mounted() {
    this.$store.dispatch("announcements/loadUnauthorized");
  },
};
</script>
