import { roundToNearestMinutes, addMinutes } from "date-fns";

/**
 * Returns true if the given date has a quarter time. Seconds are ignored.
 * @param date The date that should be checked
 * @returns True if the given date is a quarter. False otherwise. Seconds are ignored.
 */
export function isQuarter(date: Date) {
  return date.getMinutes() % 15 === 0;
}

/**
 * Takes a date and rounds it to the next quarter hour that lies in the future. Only rounds if the
 * given date is not a quarter already.
 *
 * **Examples**
 *
 * 12:03:24 -> 12:15:00
 *
 * 12:59:59 -> 13:00:00
 * @param date Optional - A date that should be rounded to the next quarter. If none given, takes
 * the current date.
 * @returns The date that is rounded to the next future quarter.
 */
export function nextQuarter(date?: Date) {
  const d = date ?? new Date();

  // If the date is already quarter, we don't need to round it.
  if (isQuarter(d)) {
    return d;
  }

  const roundedDate = roundToNearestMinutes(d, { nearestTo: 15 });

  // If the function rounded down, we need to add 15 minutes
  // to get to the next quarter in the future
  if (roundedDate < d) {
    return addMinutes(roundedDate, 15);
  }

  return roundedDate;
}

// https://stackoverflow.com/a/1353711
export function isValid(date: Date) {
  return date instanceof Date && !Number.isNaN(date.getTime());
}

export default nextQuarter;
