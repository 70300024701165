<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="show" persistent max-width="800px">
        <v-card v-if="loading" outlined color="navbar">
          <v-container>
            <v-row justify="center">
              <v-spacer></v-spacer>
              <v-col cols="1">
                <v-card-text>
                  <v-progress-circular
                    :color="isUndefOrNull(type) ? type.color : undefined"
                    indeterminate
                  >
                  </v-progress-circular>
                </v-card-text>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-container>
        </v-card>
        <v-card v-if="!loading" class="text-center" outlined color="navbar">
          <v-card-title class="text-center">
            <v-container>
              <v-row justify="center">
                <v-col cols="12">
                  <v-icon v-if="!isUndefOrNull(type)" x-large :color="type.color">
                    {{ type.icon }}
                  </v-icon>
                </v-col>
                <br />
                <span v-if="!isUndefOrNull(title)" class="h2 ml-2 white--text">{{ title }}</span>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col v-if="!isUndefOrNull(subtitle)" cols="12">
                  <h5 class="white--text">{{ subtitle }}</h5>
                </v-col>
                <v-col v-if="!isUndefOrNull(validInput)" cols="12">
                  <v-text-field
                    v-model="inputText"
                    label="Input"
                    required
                    clearable
                    dark
                    filled
                    :hint="'You need to enter \'' + validInput + '\' in order to continue!'"
                    outlined
                    :error="wrongInput"
                    @change="validateInput()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="full-width">
            <v-spacer></v-spacer>
            <v-btn
              v-if="isUndefOrNull(showCancelButton) || showCancelButton"
              color="error"
              @click="onCancel()"
            >
              Cancel
            </v-btn>
            <v-btn class="mr-8" color="success" @click="onOk()"> Ok </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    validInput: {
      type: String,
      required: false,
    },
    showCancelButton: {
      type: Boolean,
      required: false,
    },
    type: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      show: true,
      inputText: "",
      wrongInput: false,
      loading: false,
    };
  },
  methods: {
    isUndefOrNull(obj) {
      return obj === undefined || obj === null;
    },
    onCancel() {
      this.$emit("cancel");
    },
    onOk() {
      this.validateInput();
      if (!this.wrongInput) {
        this.loading = true;
        this.$emit("ok");
      }
    },
    validateInput() {
      if (this.inputText === undefined) return;
      if (this.validInput === undefined) return;
      if (this.inputText !== this.validInput) {
        this.wrongInput = true;
      } else {
        this.wrongInput = false;
      }
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
