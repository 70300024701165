import Vue from "vue";
import AxiosRestService from "@/network/axiosRestService";

const initialState = () => ({
  privateZones: [],
  loading: false,
  deleting: false,
  adding: false,
  tudCodes: [],
  loadingTudCodes: false,
  errorLoadingTudCodes: false,
  hasTudAccess: false,
  removingTudAccess: false,
  errorRemovingTudAccess: false,
  successRemovingTudAccess: false,
});

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    index: (state) => state.index,
    privateZones: (state) => state.privateZones,
    loading: (state) => state.loading,
    deleting: (state) => state.deleting,
    adding: (state) => state.adding,
    tudCodes: (state) => state.tudCodes,
    loadingTudCodes: (state) => state.loadingTudCodes,
    errorLoadingTudCodes: (state) => state.errorLoadingTudCodes,
    hasTudAccess: (state) => state.hasTudAccess,
    removingTudAccess: (state) => state.removingTudAccess,
    errorRemovingTudAccess: (state) => state.errorRemovingTudAccess,
    successRemovingTudAccess: (state) => state.successRemovingTudAccess,
  },
  // -----------------------------------------------------------------
  mutations: {
    setIndex: (state, codes) => Vue.set(state, "index", codes),
    setPrivateZones: (state, privateZones) => Vue.set(state, "privateZones", privateZones),
    setIsLoading: (state, loading) => Vue.set(state, "loading", loading),
    setIsDeleting: (state, deleting) => Vue.set(state, "deleting", deleting),
    setIsAdding: (state, adding) => Vue.set(state, "adding", adding),
    setTudCodes: (state, tudCodes) => Vue.set(state, "tudCodes", tudCodes),
    setLoadingTudCodes: (state, loadingTudCodes) =>
      Vue.set(state, "loadingTudCodes", loadingTudCodes),
    setErrorLoadingTudCodes: (state, errorLoadingTudCodes) =>
      Vue.set(state, "errorLoadingTudCodes", errorLoadingTudCodes),
    setHasTudAccess: (state, hasTudAccess) => Vue.set(state, "hasTudAccess", hasTudAccess),
    setRemovingTudAccess: (state, removingTudAccess) =>
      Vue.set(state, "removingTudAccess", removingTudAccess),
    setErrorRemovingTudAccess: (state, errorRemovingTudAccess) =>
      Vue.set(state, "errorRemovingTudAccess", errorRemovingTudAccess),
    setSuccessRemovingTudAccess: (state, successRemovingTudAccess) =>
      Vue.set(state, "successRemovingTudAccess", successRemovingTudAccess),
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  // -----------------------------------------------------------------
  actions: {
    loadIndex: (context) =>
      AxiosRestService.get("/codes").then((response) => {
        context.commit("setIndex", response.data);
      }),
    fetchPrivateZones: (context) => {
      context.commit("setIsLoading", true);
      return AxiosRestService.get("/codes/visitor_private_zones")
        .then((response) => {
          context.commit("setPrivateZones", response.data);
          return Promise.resolve(context.state.privateZones);
        })
        .finally(() => context.commit("setIsLoading", false));
    },
    deletePrivateZone: (context, zoneId) => {
      context.commit("setIsDeleting", true);
      return AxiosRestService.post("/codes/visitor_private_code_delete", {
        codeId: zoneId,
      })
        .then((response) => {
          context.dispatch("fetchPrivateZones");
          return response.data;
        })
        .finally(() => context.commit("setIsDeleting", false));
    },
    addPrivateZone: (context, code) => {
      context.commit("setIsAdding", true);
      return AxiosRestService.post("/codes/visitor_add_code", {
        name: code,
      })
        .then((response) => {
          context.dispatch("fetchPrivateZones");
          return response.data;
        })
        .finally(() => context.commit("setIsAdding", false));
    },
    checkTuAuthStatus: (context) =>
      AxiosRestService.get("/users/checkTuAuthorizationStatus").then((response) => {
        if (response.status === 200) {
          context.commit("setHasTudAccess", true);
        } else {
          context.commit("setHasTudAccess", false);
        }
        return response.data;
      }),
    removeTudAccess: (context) => {
      context.commit("setRemovingTudAccess", true);
      return AxiosRestService.delete("/users/removeTuAuthorization")
        .then(() => {
          context.dispatch("checkTuAuthStatus");
          context.dispatch("fetchPrivateZones");
        })
        .catch(() => context.commit("setErrorRemovingTudAccess", true))
        .finally(() => context.commit("setRemovingTudAccess", false));
    },
    prepareTuAuthorize: (context, tuIdInput) =>
      AxiosRestService.post("/codes/prepareTuAuthorize", {
        tuid: tuIdInput,
      }).then((response) => response.data),
  },
};
