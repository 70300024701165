export enum NotificationTypes {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

let counter = 1;

export default class Notification {
  public id: number;

  public progress?: number;

  public type?: NotificationTypes;

  constructor(
    public message: string,
    public timeout = 10000,
    public displayTimeout = false,
    public creationDate = Date.now(),
  ) {
    this.id = counter;
    counter += 1;
  }

  public setType(type: NotificationTypes) {
    this.type = type;
  }
}
