import Vue from "vue";
import AxiosRestService from "@/network/axiosRestService";
import i18n from "@/i18n";
import Notification from "@/model/notification";

const initialState = () => ({
  loading: false,
  blockedSites: [],
});

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    loading: (state) => state.loading,
    blockedSites: (state) => state.blockedSites,
  },
  mutations: {
    setLoading: (state, loading) => Vue.set(state, "loading", loading),
    setBlockedSites: (state, blockedSites) => Vue.set(state, "blockedSites", blockedSites),
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  actions: {
    unblock: async (context, id) => {
      context.commit("setLoading", true);
      let responseCode = 0;
      await AxiosRestService.put("/blockedUsers/unblock", { id })
        .then((response) => {
          responseCode = response.status;
          context.dispatch(
            "notifications/success",
            new Notification(i18n.t("User successfully unblocked!")),
            { root: true },
          );
        })
        .catch((error) => {
          responseCode = error.response?.status;
          context.dispatch(
            "notifications/error",
            new Notification(
              i18n.t(
                "Something went wrong while unblocking the user. Please try again later or contact the support.",
              ),
            ),
            { root: true },
          );
        })
        .finally(() => {
          context.commit("setLoading", false);
        });
      return responseCode;
    },
    block: async (context, data) => {
      const payload = {
        user_id: data.user.id,
        zone_id: data.zoneIds[0],
        block_reason: data.reason,
      };

      context.commit("setLoading", true);

      let responseCode = 0;

      await AxiosRestService.post("/blockedUsers/block", payload)
        .then((response) => {
          responseCode = response.status;
        })
        .catch((error) => {
          responseCode = error.response?.status;
        })
        .finally(() => {
          context.commit("setLoading", false);
        });

      return responseCode;
    },
    getBlockedSites: async (context, data) => {
      context.commit("setLoading", true);
      let responseCode = 0;
      let responseData = [];
      await AxiosRestService.get(`/zones/${data.zoneIds[0]}/sites`, {
        headers: {
          Accept: "application/vnd.api+json",
        },
      })
        .then((response) => {
          responseData = response.data.data;
          responseCode = response.status;
        })
        .catch((error) => {
          responseCode = error.response?.status;
        })
        .finally(() => {
          context.commit("setLoading", false);
        });
      for (let i = 0; i < responseData.length; i += 1) {
        responseData[i] = responseData[i].attributes.name;
      }
      context.commit("setBlockedSites", responseData);
      return responseCode;
    },
  },
};
