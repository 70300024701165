import AxiosRestService from "@/network/axiosRestService";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import BaseModule from "../module";
import { RootState, State } from "../types";

interface DocumentsState extends State {
  documents: string[];
  agreement: boolean;
  rememberDecision: boolean;
  globalAgreement: boolean;
}

class DocumentsModule extends BaseModule<DocumentsState> {
  protected initialState(): DocumentsState {
    return {
      documents: [],
      agreement: false,
      rememberDecision: false,
      globalAgreement: true,
    };
  }

  protected buildGettersTree(): GetterTree<DocumentsState, RootState> {
    return {
      documents: (state) => state.documents,
      agreement: (state) => state.agreement,
      rememberDecision: (state) => state.rememberDecision,
      globalAgreement: (state) => state.globalAgreement,
    };
  }

  protected buildMutationsTree(): MutationTree<DocumentsState> {
    return {
      updateDocuments(state, value: string[]) {
        state.documents = value;
      },
      updateAgreement(state, value: boolean) {
        state.agreement = value;
      },
      updateRememberDecision(state, value: boolean) {
        state.rememberDecision = value;
      },
      updateGlobalAgreement(state, value: boolean) {
        state.globalAgreement = value;
      },
    };
  }

  protected buildActionsTree(): ActionTree<DocumentsState, RootState> {
    return {
      fetchDocumentsForSite(context, siteId: number) {
        AxiosRestService.get<{ data: { attributes: { documents: never } } }>(
          `/sites/${siteId}/documents/get-documents`,
          {
            headers: { Accept: "application/vnd.api+json" },
          },
        ).then((result) => {
          context.commit("updateDocuments", result.data.data.attributes.documents);
        });
      },
      fetchAgreementForSite(context, siteId: number) {
        AxiosRestService.get<{
          data: { attributes: { accepted: boolean; rememberDecision: boolean } };
        }>(`/sites/${siteId}/documents/has-accepted`, {
          headers: { Accept: "application/vnd.api+json" },
        }).then((result) => {
          context.commit("updateAgreement", result.data.data.attributes.accepted);
          context.commit("updateRememberDecision", result.data.data.attributes.rememberDecision);
        });
      },
      fetchGlobalAgreement(context) {
        AxiosRestService.get<{ data: { attributes: { accepted: boolean } } }>(
          "/documents/has-accepted-global",
          {
            headers: { Accept: "application/vnd.api+json" },
          },
        ).then((result) => {
          context.commit("updateGlobalAgreement", result.data.data.attributes.accepted);
        });
      },
      acceptGlobalDocuments() {
        const rememberDecision = {
          data: {
            type: "usersDocuments",
            id: -1,
            attributes: {
              rememberDecision: true,
            },
          },
        };
        // Accept privacy policy, id = 1
        AxiosRestService.patch("/documents/1/accept", rememberDecision, {
          headers: {
            Accept: "application/vnd.api+json",
          },
        });
        // Accept terms of service, id = 2
        AxiosRestService.patch("/documents/2/accept", rememberDecision, {
          headers: {
            Accept: "application/vnd.api+json",
          },
        });
      },
    };
  }
}

export default new DocumentsModule(true);
