import Vue from "vue";
import AxiosRestService from "@/network/axiosRestService";
import Notification from "@/model/notification";

const initialState = () => ({
  index: [],
  loading: false,
  errorLoadingIndex: false,
});

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    index: (state) => state.index,
    loading: (state) => state.loading,
    errorLoadingIndex: (state) => state.errorLoadingIndex,
  },
  // -----------------------------------------------------------------
  mutations: {
    setIndex: (state, roles) => Vue.set(state, "index", roles),
    setLoading: (state, loading) => Vue.set(state, "loading", loading),
    setErrorLoadingIndex: (state, errorLoadingIndex) =>
      Vue.set(state, "errorLoadingIndex", errorLoadingIndex),
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  // -----------------------------------------------------------------
  actions: {
    downloadReport: ({ dispatch, commit }, payload) => {
      commit("setLoading", true);
      const { month } = payload;
      const { year } = payload;
      const tenantName = payload.tenantName.replace(/\s+/g, "-");

      return AxiosRestService.get("/transactions/getMonthlyReservationReportAsCSV", {
        params: {
          month,
          year,
        },
        // responseType: 'blob', // important
      })
        .then((response) => {
          if (response.data.err) {
            dispatch("notifications/warning", new Notification(response.data.err), { root: true });
          } else {
            // from https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "text/comma-separated-values" }),
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${year}_${month.toString().padStart(2, "0")}_${tenantName}_BnP_Reservierungen.csv`,
            );
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => commit("setLoading", false));
    },
  },
};
