<template>
  <div>
    <input-dialog
      v-for="current in $store.getters['dialogs/currentDialog']"
      :key="current.title"
      :title="current.title"
      :subtitle="current.subtitle"
      :valid-input="current.validInput"
      :show-cancel-button="current.showCancelButton"
      :type="current.type"
      @ok="onOK(current)"
      @cancel="onCancel(current)"
    >
    </input-dialog>
  </div>
</template>

<script>
import InputDialog from "@/components/InputDialog.vue";

export default {
  components: {
    InputDialog,
  },
  methods: {
    async onOK(current) {
      if (!(current?.okAction === undefined || current.okAction === null)) {
        await current.okAction();
      }
      this.$store.commit("dialogs/removeDialog", current.id);
    },
    onCancel(current) {
      this.$store.commit("dialogs/removeDialog", current.id);
      if (!(current?.cancelAction === undefined || current.cancelAction === null)) {
        current.cancelAction();
      }
    },
  },
};
</script>

<style scoped></style>
