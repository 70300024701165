import Vue from "vue";
import AxiosRestService from "@/network/axiosRestService";

const initialState = () => ({
  index: [],
  loading: false,
  errorLoadingIndex: false,
});

export default {
  resource: "products",
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    index: (state) => state.index,
    loading: (state) => state.loading,
    errorLoadingIndex: (state) => state.errorLoadingIndex,
  },
  // -----------------------------------------------------------------
  mutations: {
    setIndex: (state, products) => Vue.set(state, "index", products),
    setLoading: (state, loading) => Vue.set(state, "loading", loading),
    setErrorLoadingIndex: (state, errorLoadingIndex) =>
      Vue.set(state, "errorLoadingIndex", errorLoadingIndex),
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  // -----------------------------------------------------------------
  actions: {
    loadIndex: (context) => {
      context.commit("setLoading", true);
      return AxiosRestService.get("/products")
        .then((response) => {
          context.commit("setIndex", response.data);
          context.commit("setErrorLoadingIndex", false);
        })
        .catch(() => context.commit("setErrorLoadingIndex", true))
        .finally(() => {
          context.commit("setLoading", false);
        });
    },
  },
};
