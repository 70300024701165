<template>
  <div>
    <v-snackbar v-model="offline" timeout="20000" color="white">
      <span style="color: #333">{{ $t("offline-notification") }}</span>
      <template #action="{ attrs }">
        <v-btn text color="black" :ripple="false" v-bind="attrs" @click="offline = false">
          <span style="color: #333">{{ $t("Close") }}</span>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      offline: false,
      show: false,
    };
  },
  mounted() {
    window.addEventListener("online", this.onChange);
    window.addEventListener("offline", this.onChange);
    this.onChange();
  },
  beforeUnmount() {
    window.removeEventListener("online", this.onChange);
    window.removeEventListener("offline", this.onChange);
  },
  methods: {
    onChange() {
      this.offline = !navigator.onLine;
      this.$emit(this.offline ? "offline" : "online");
    },
  },
};
</script>

<style scoped></style>
