import Vue from "vue";
import axiosRestService from "@/network/axiosRestService";
import parseJsonApiResponse from "@/api/jsonapi/ResponseParser";

const initialState = () => ({
  myReservations: [],
  singleReservation: {},
  isLoading: false,
  hasValidToken: false,
  hasError: false,
  errorMessage: null,
});

export const INCLUDES_FOR_RESERVATION_CARD = [
  "devices",
  "devices.deviceAccessMethods",
  "devices.deviceTypes",
  "devices.media",
  "events",
  "sites",
  "sites.barriers",
  "sites.locations",
  "sites.media",
  "sites.zones",
  "sites.zones.tenants",
  "childReservations.devices.deviceAccessMethods",
  "childReservations.devices.deviceTypes",
  "childReservations.devices.media",
  "controlTokens",
  "users",
];

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    myReservations: (state) => state.myReservations,
    reservationsCount: (state, getters) => getters.myReservations.length,
    singleReservation: (state) => state.singleReservation,
    isLoading: (state) => state.isLoading,
    hasValidToken: (state) => state.hasValidToken,
    hasError: (state) => state.hasError,
    errorMessage: (state) => state.errorMessage,
    allReservations: (state) => [...state.myReservations, ...state.singleReservation],
    // TODO fix the following error

    getBarriersOnPath: (state) => (reservationToken, pathNr) => {
      const reservation = state.getReservationByToken(reservationToken);
      // To make a hardcopy and not modify the state
      const barriers = Object.assign(reservation.site.barriers);
      // TODO fix the following error

      return barriers.filter((barrier) => barrier.path_number === pathNr);
    },
    // TODO fix the following error

    getReservationByToken: (state) => (reservationToken) =>
      state.allReservations.find(
        // TODO fix the following error

        (reservation) => reservation.token === reservationToken,
      ),
  },
  // -----------------------------------------------------------------
  mutations: {
    setMyReservations: (state, myReservations) => Vue.set(state, "myReservations", myReservations),
    setSingleReservation: (state, singleReservation) =>
      Vue.set(state, "singleReservation", singleReservation),
    setIsLoading: (state, isLoading) => Vue.set(state, "isLoading", isLoading),
    setHasValidToken: (state, hasValidToken) => Vue.set(state, "hasValidToken", hasValidToken),
    setHasError: (state, hasError) => Vue.set(state, "hasError", hasError),
    setErrorMessage: (state, { errorMessage, errorCode }) =>
      Vue.set(state, "errorMessage", { errorMessage, errorCode }),
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  // -----------------------------------------------------------------
  actions: {
    fetchReservations: (context) =>
      axiosRestService.get("/reservations/personal_index").then((response) => {
        context.commit("setMyReservations", response.data);
        return Promise.resolve(context.state.myReservations);
      }),
    fetchAllowedSingleReservation: (context, token) => {
      context.commit("setIsLoading", true);
      return axiosRestService
        .get(`/reservations/remote/${token}`, {
          params: {
            include: INCLUDES_FOR_RESERVATION_CARD.join(","),
          },
          headers: { Accept: "application/vnd.api+json" },
        })
        .then((response) => {
          const data = parseJsonApiResponse(response)[0];
          const reservation = {
            id: data.apiId,
            ...data,
          };
          context.commit("setSingleReservation", reservation);
          context.commit("setHasValidToken", true);
          context.commit("setHasError", false);
          context.commit("setErrorMessage", false);
        })
        .catch((error) => {
          context.commit("setErrorMessage", {
            errorCode: error.response.status,
            errorMessage: error.response.data.msg,
          });
          context.commit("setHasError", true);
          context.commit("setHasValidToken", false);
          context.commit("setSingleReservation", null);
          if (error.response.status) {
            context.commit("setHasValidToken", false);
          }
        })
        .finally(() => {
          context.commit("setIsLoading", false);
        });
    },
  },
};
