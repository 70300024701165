import Vue from "vue";
import Vuetify, { colors } from "vuetify/lib";
import en from "vuetify/src/locale/en";
import de from "vuetify/src/locale/de";
import it from "vuetify/src/locale/it";
import fr from "vuetify/src/locale/fr";

import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export const options = {
  lang: {
    locales: {
      de,
      en,
      it,
      fr,
    },
    current: "de",
  },
  theme: {
    themes: {
      light: {
        primary: "#000",
        accent: "#f50537",
        success: "#0DA20D",
        error: "#BB0A30",
        info: "#0084bd",
        green: "#0DA20D",
        red: "#FF664C",
        "status-restarting": colors.amber.darken4,
        "status-generating": colors.purple.accent3,
        "status-pending": colors.amber.darken1,
        "status-active": colors.green.lighten1,
        "status-unreachable": colors.red.darken2,
        "status-all": colors.blue.lighten1,
        "border-radius-root": 0,
        "btn-border-radius": 0,
        navbar: "#333",
      },
    },
  },
};

export default new Vuetify(options);
