import Vue from "vue";
import AxiosRestService from "@/network/axiosRestService";

const initialState = () => ({
  index: [],
  loading: false,
  errorLoadingIndex: false,
});

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    index: (state) => state.index,
    loading: (state) => state.loading,
    errorLoadingIndex: (state) => state.errorLoadingIndex,
  },
  // -----------------------------------------------------------------
  mutations: {
    setIndex: (state, roles) => Vue.set(state, "index", roles),
    setLoading: (state, loading) => Vue.set(state, "loading", loading),
    setErrorLoadingIndex: (state, errorLoadingIndex) =>
      Vue.set(state, "errorLoadingIndex", errorLoadingIndex),
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  // -----------------------------------------------------------------
  actions: {
    loadIndex: (context) => {
      context.commit("setLoading", true);
      return AxiosRestService.get("/reservations/historyWithTransactions")
        .then((response) => {
          context.commit("setIndex", response.data);
          context.commit("setErrorLoadingIndex", false);
        })
        .catch(() => context.commit("setErrorLoadingIndex", true))
        .finally(() => context.commit("setLoading", false));
    },
    downloadInvoice: (context, transaction) =>
      AxiosRestService.get(`/transactions/getReportFor/${transaction.id}`, {
        responseType: "blob", // important
      }).then((response) => {
        // from https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${transaction.type}_${transaction.id}.pdf`);
        document.body.appendChild(link);
        link.click();
      }),
  },
};
