import Vue from "vue";
import Vuex from "vuex";
import { AsyncStorage, VuexPersistence } from "vuex-persist";
import localForage from "localforage";
import UserRepository from "@/network/repositories/userRepository";
import RestService from "@/network/axiosRestService";
import User from "@/model/user";
import i18n from "@/i18n";
import Notification from "@/model/notification";
import { PersistenceStore, RootState } from "./types";

import modules from "./modules";
import { AxiosError } from "axios";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: "vuex",
  storage: localForage as AsyncStorage,
  asyncStorage: true,
});

const debug = process.env.NODE_ENV !== "production";
const repository = new UserRepository(RestService);

const initialState = () =>
  ({
    user: undefined,
    loginResponseCode: undefined,
    loading: false,
    resettingPassword: false,
    resetPasswordSuccess: false,
    invitationTokenValid: false,
    invitationEmail: "",
    registrationSuccessful: false,
    searchString: "",
  }) as RootState;

const store = new Vuex.Store<RootState>({
  state: initialState,
  getters: {
    user: (state) => (state.user ? User.createInstanceFromObject(state.user) : undefined),
    loading: (state) => state.loading,
    loginResponseCode: (state) => state.loginResponseCode,
    resettingPassword: (state) => state.resettingPassword,
    resetPasswordSuccess: (state) => state.resetPasswordSuccess,
    searchString: (state) => state.searchString,
    userHasFeatures(state) {
      return (features: string[] | string) => {
        if (!state.user) {
          return false;
        }
        const featuresArray = !Array.isArray(features) ? [features] : features;
        return state.user.features.some((feature) => featuresArray.includes(feature));
      };
    },
    userHasOnlyFeatures(state) {
      return (features: string[] | string) => {
        if (!state.user) {
          return false;
        }
        const featuresArray = !Array.isArray(features) ? [features] : features;
        return state.user.features.every((feature) => featuresArray.includes(feature));
      };
    },
    getRole: (state) => {
      if (!state.user) {
        return "";
      }
      return state.user.role.name;
    },
    isAdmin: (state) => {
      if (!state.user) {
        return false;
      }
      return state.user.role.name === "Admin";
    },
    userIsRM: (state) => {
      if (!state.user) {
        return false;
      }
      return state.user.role.name === "Reservation Manager";
    },
    userIsSuperReservationManager: (state, getters) => {
      const currentUserRole = getters.getRole;
      return currentUserRole === "Reservation Manager" && getters.user.type === "SUPER";
    },
    getUsersZones: (state) => {
      if (!state.user) {
        return [];
      }
      return state.user.zones;
    },
    getTenantName: (state) => {
      if (!state.user) {
        return "";
      }
      return state.user.tenant.name;
    },
    getSessionTenant: (state) => {
      if (!state.user) {
        return "";
      }
      return state.user.sessionTenant;
    },
    getAccountBalance: (state) => {
      if (!state.user) {
        return 0;
      }
      return state.user.accountBalance;
    },
    invitationTokenValid: (state) => state.invitationTokenValid,
    invitationEmail: (state) => state.invitationEmail,
    registrationSuccessful: (state) => state.registrationSuccessful,
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user;
    },
    setLoading: (state, isLoading) => {
      state.loading = isLoading;
    },
    setLoginResponseCode: (state, responseCode) => {
      state.loginResponseCode = responseCode;
    },
    setResettingPassword: (state, resettingPassword) => {
      state.resettingPassword = resettingPassword;
    },
    setResetPasswordSuccess: (state, value) => {
      state.resetPasswordSuccess = value;
    },
    setInvitationTokenValid: (state, valid) => {
      state.invitationTokenValid = valid;
    },
    setInvitationEmail: (state, email) => {
      state.invitationEmail = email;
    },
    setRegistrationSuccessful: (state, successful) => {
      state.registrationSuccessful = successful;
    },
    setSearchString: (state, searchString) => {
      state.searchString = searchString;
    },
    setLicencePlate: (state, licence) => {
      if (state.user) {
        state.user.licencePlate = licence;
      }
    },
    updateAccountBalance(state, accountBalance) {
      if (!state.user) {
        return;
      }
      state.user.accountBalance = parseFloat(accountBalance);
    },
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  actions: {
    updateSearchString(context, searchString) {
      context.commit("setSearchString", searchString);
    },
    updateLicencePlate(context, newLicencePlate) {
      context.commit("setLicencePlate", newLicencePlate);
    },
    async attachAudiCodes() {
      console.log("attaching audi auto-subscribe codes");
      await RestService.patch(
        "/users/me/codes?tenant-prefix=Audi",
        {},
        { headers: { Accept: "application/vnd.api+json" } },
      );
    },
    async fetchUserInfo(context) {
      try {
        const user = await repository.me();
        context.commit("setUser", user);
        await context.dispatch("translateTo", user.language);
        return user;
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 429) {
          await context.dispatch(
            "notifications/error",
            new Notification(
              i18n.t(
                "Your account has been temporarily blocked due to too many login attempts.",
              ) as string,
              60 * 1000,
              true,
            ),
          );
        }
        if (error instanceof AxiosError && error.response?.status === 401) {
          // If we are logging in using the myAudiButton
          if (
            context.getters["oidcRegister/subSet"] &&
            !context.getters["oidcRegister/showVwBnPMappingDialogue"]
          ) {
            await context.dispatch(
              "notifications/error",
              new Notification(i18n.t("vw-account-not-yet-registered") as string),
            );
            context.commit("oidcRegister/setShowVwBnPMappingDialogue", true);
          } else {
            await context.dispatch(
              "notifications/error",
              new Notification(i18n.t("Invalid username or password. Please try again") as string),
            );
          }
        }
        if (error instanceof AxiosError && error.response?.status === 403) {
          await context.dispatch(
            "notifications/warning",
            new Notification(i18n.t("finish-registration-warning") as string),
          );
        }
        if (error instanceof AxiosError) {
          context.commit("setLoginResponseCode", error.response?.status);
        }
      }
      return undefined;
    },
    async login(context, credentials = { username: "", password: "" }) {
      await context.dispatch("reset");

      context.commit("setLoading", true);
      try {
        const user = await repository.login(credentials);
        context.commit("setUser", user);

        // The user object contains the stored user language.
        // Translate the portal after user is logged in.
        context.dispatch("translateTo", user.language);

        // Setting the announcements if there are any
        await context.dispatch("announcements/setCurrentAnnouncements", user.announcements);

        return user;
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 429) {
          context.dispatch(
            "notifications/error",
            new Notification(
              i18n.t(
                "Your account has been temporarily blocked due to too many login attempts.",
              ) as string,
              60 * 1000,
              true,
            ),
          );
        }
        if (error instanceof AxiosError && error.response?.status === 401) {
          // If we are logging in using the myAudiButton
          if (
            context.getters["oidcRegister/subSet"] &&
            !context.getters["oidcRegister/showVwBnPMappingDialogue"]
          ) {
            context.dispatch(
              "notifications/error",
              new Notification(i18n.t("Invalid username or password. Please try again") as string),
            );
            context.commit("oidcRegister/setShowVwBnPMappingDialogue", true);
          } else {
            context.dispatch(
              "notifications/error",
              new Notification(i18n.t("Invalid username or password. Please try again") as string),
            );
          }
        }
        if (error instanceof AxiosError && error.response?.status === 403) {
          context.dispatch(
            "notifications/warning",
            new Notification(i18n.t("finish-registration-warning") as string),
          );
        }
        if (error instanceof AxiosError) {
          context.commit("setLoginResponseCode", error.response?.status);
        }
      } finally {
        context.commit("setLoading", false);
      }
      return undefined;
    },
    async logout(context) {
      await context.dispatch("reset");

      try {
        await repository.logout();
      } catch {
        // TODO error handling
      }
    },
    async resetPassword(context, payload) {
      context.commit("setResettingPassword", true);
      try {
        await repository.resetPassword(payload);
        context.commit("setResetPasswordSuccess", true);
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 400) {
          await context.dispatch(
            "notifications/error",
            new Notification(i18n.t("E-mail address does not exist.") as string),
          );
        }
      } finally {
        context.commit("setResettingPassword", false);
      }
    },
    async register(context, user) {
      context.commit("setLoading", true);
      try {
        const response = await repository.register(user);
        if ("error-code" in response) {
          context.commit("setRegistrationSuccessful", false);
          context.dispatch(
            "notifications/error",
            new Notification(i18n.t(response.message) as string, -1),
          );
        } else {
          context.commit("setRegistrationSuccessful", true);
        }
      } catch (error) {
        context.commit("setRegistrationSuccessful", false);
        if (error instanceof AxiosError && error.response?.status === 409) {
          context.dispatch(
            "notifications/error",
            new Notification(i18n.t("register-conflict") as string),
          );
        } else {
          context.dispatch(
            "notifications/error",
            new Notification(i18n.t("The request has error, please contact your Admin!") as string),
          );
        }
      } finally {
        context.commit("setLoading", false);
      }
    },
    async checkInvitationToken(context, token) {
      context.commit("setInvitationTokenValid", false);
      try {
        const email = await repository.checkInvitationToken(token);
        context.commit("setInvitationTokenValid", true);
        context.commit("setInvitationEmail", email);
      } catch {
        context.commit("setInvitationTokenValid", false);
      }
    },
    async fetchAccountBalance(context) {
      context.commit("setLoading", true);
      const newAccountBalance = await repository.getAccountBalance();
      context.commit("updateAccountBalance", newAccountBalance);
      context.commit("setLoading", false);
    },
    setLoadingFalse(context) {
      context.commit("setLoading", false);
    },
    reset({ commit }) {
      // Copy the modules and remove the language key, to prevent error message
      // 'Mutation type language/reset not found'
      // It would be better if we could reset the language properly, but for this
      // we would need access to outside functions inside the language store to load
      // the user language from the backend.
      // Also remove the countries module, since this shouldn't get reset
      const customizedModules = { ...modules };
      delete customizedModules.language;
      delete customizedModules.countries;
      delete customizedModules.oidcRegister;

      Object.keys(customizedModules).forEach((moduleName) => {
        commit(`${moduleName}/reset`);
      });
      commit("reset");
    },
  },
  modules,
  strict: debug,
  plugins: [vuexLocal.plugin],
}) as PersistenceStore<RootState>;

RestService.addResponseErrorHandler(() => {
  store.dispatch(
    "notifications/error",
    new Notification(i18n.t("Seems like an error occurred, please reload the page") as string, -1),
  );
});

export default store;
