import Model from "./model";

export enum Roles {
  ADMIN = "Admin",
  RESERVATION_MANAGER = "Reservation Manager",
  VISITOR = "Visitor",
  SUPPORT = "Support",
}

export default interface Role extends Model {
  name: Roles;
}
