import Vue from "vue";
// import Element from 'element-ui';
import VueMeta from "vue-meta";

// Problem witht that is that it wont change to english
// import locale from 'element-ui/lib/locale/lang/de';
import axiosRestService from "@/network/axiosRestService";
import * as VueGoogleMaps from "vue2-google-maps";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { User, UserManager } from "oidc-client-ts";
import * as Oidc from "oidc-client-ts";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import i18n from "./i18n";
import store from "./store";
import vuetify from "./plugins/vuetify";

// Globals
Vue.prototype.$audiMinPrebookTimeMins = 30;
Vue.prototype.$audiMinReservationDurationMins = 45;

Vue.config.productionTip = false;
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

async function loadMaps() {
  await store.restored;
  Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
      libraries: "places",
      region: "DE",
      language: store.getters.language,
    },
  });
}

loadMaps();

Vue.directive("scroll", {
  inserted(el, binding) {
    function f(evt: Event) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    }

    window.addEventListener("scroll", f);
  },
});

if (
  process.env.NODE_ENV === "production" &&
  window.location.hostname.endsWith("staging.booknpark.de")
) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["*.booknpark.de", "*.book-n-park.de"],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

Oidc.Log.setLogger(console);
Oidc.Log.setLevel(Oidc.Log.DEBUG);
console.log("in main.ts");
console.log(window.location);
const url = `${window.location.protocol}//${window.location.host}/auth`;
console.log("Callback URL");
console.log(url);
if (!Vue.prototype.$audiOidcUserManager) {
  console.log("Initializing new user manager");
  Vue.prototype.$audiOidcUserManager = new UserManager({
    authority: "https://identity.vwgroup.io",
    client_id: "34ff0454-483c-4c80-9bc1-796107f11ea1@apps_vw-dilab_com",
    redirect_uri: url,
    client_secret: "3e2e4eeb61ca6c901e8a5b2ab83c5dc8c19548b52816b1932f5d4230daec3c34",
  });
}
Vue.prototype.$audiOidcUserManager.getUser().then((user: User | null) => {
  if (user?.access_token) {
    axiosRestService.setBearerToken(user.access_token);
  }
});

async function setLanguage() {
  await store.restored;
  i18n.locale = store.getters.language;

  // We don't want to update the database on the login page, since the user isn't logged in yet
  store.dispatch("translateToWithoutDB", store.getters.language);
}

async function clearOldNotifications() {
  await store.restored;
  store.dispatch("notifications/removeOldNotifications", store.getters.notifications);
  store.commit("oidcRegister/setShowVwBnPMappingDialogue", false);
}

setLanguage();
clearOldNotifications();
