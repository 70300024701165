import Vue from "vue";
import AxiosRestService from "@/network/axiosRestService";

const initialState = () => ({
  index: [],
  loading: false,
  successDeletingItem: false,
  successAddingItem: false,
  successEditingItem: false,
  errorLoadingIndex: false,
  errorDeletingItem: false,
  errorAddingItem: false,
  errorEditingItem: false,
});

export default {
  resource: "events",
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    index: (state) => state.index,
    loading: (state) => state.loading,
    adding: (state) => state.adding,
    deleting: (state) => state.deleting,
    editing: (state) => state.editing,
    successAddingItem: (state) => state.successAddingItem,
    successEditingItem: (state) => state.successEditingItem,
    successDeletingItem: (state) => state.successDeletingItem,
    errorLoadingIndex: (state) => state.errorLoadingIndex,
    errorDeletingItem: (state) => state.errorDeletingItem,
    errorAddingItem: (state) => state.errorAddingItem,
    errorEditingItem: (state) => state.errorEditingItem,
  },
  // -----------------------------------------------------------------
  mutations: {
    setIndex: (state, roles) => Vue.set(state, "index", roles),
    setIsAdding: (state, adding) => Vue.set(state, "adding", adding),
    setIsEditing: (state, editing) => Vue.set(state, "adding", editing),
    setErrorEditingItem: (state, editing) => Vue.set(state, "errorEditingItem", editing),
    setSuccessEditingItem: (state, editing) => Vue.set(state, "successEditingItem", editing),
    setIsDeleting: (state, deleting) => Vue.set(state, "deleting", deleting),
    setLoading: (state, loading) => Vue.set(state, "loading", loading),
    setErrorLoadingIndex: (state, errorLoadingIndex) =>
      Vue.set(state, "errorLoadingIndex", errorLoadingIndex),
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  // -----------------------------------------------------------------
  actions: {
    loadIndex: (context) => {
      context.commit("setLoading", true);
      return AxiosRestService.get("/events")
        .then((response) => {
          context.commit("setIndex", response.data);
          context.commit("setErrorLoadingIndex", false);
        })
        .catch(() => context.commit("setErrorLoadingIndex", true))
        .finally(() => context.commit("setLoading", false));
    },
    addEvent: (context, event) => {
      context.commit("setIsAdding", true);
      return AxiosRestService.post("/events/add_ajax", {
        event,
      })
        .then((response) => {
          context.dispatch("loadIndex");
          context.commit("setErrorAddingItem", false);
          context.commit("setSuccessAddingItem", true);
          return response.data;
        })
        .catch((error) => {
          context.commit("setErrorAddingItem", true);
          context.commit("setSuccessAddingItem", false);
          return Promise.reject(error);
        })
        .finally(() => context.commit("setIsAdding", false));
    },
    editEvent: (context, event) => {
      context.commit("setIsEditing", true);
      return AxiosRestService.post(`/events/edit_ajax/${event.id}`, {
        event,
      })
        .then((response) => {
          context.dispatch("loadIndex");
          context.commit("setErrorEditingItem", false);
          context.commit("setSuccessEditingItem", true);
          return response.data;
        })
        .catch((error) => {
          context.commit("setErrorEditingItem", true);
          context.commit("setSuccessEditingItem", false);
          return Promise.reject(error);
        })
        .finally(() => context.commit("setIsEditing", false));
    },

    deleteEvent: (context, event) => {
      context.commit("setIsDeleting", true);
      context.commit("setLoading", true);
      return AxiosRestService.post("/events/softDelete", {
        event_id: event.id,
      })
        .then((response) => {
          context.dispatch("loadIndex");
          return response.data;
        })
        .finally(() => {
          context.commit("setIsDeleting", false);
          context.commit("setLoading", false);
        });
    },
  },
};
